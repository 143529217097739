<template>
    <div id="pengajuan-skripsi" class="mcontainer">
        <h2 class="text-lg font-semibold color-dark">Daftar Mahasiswa Bimbingan Skripsi</h2>

        <div class="header_search search-news mt-4 flex justify-content-end">
            <div style="max-width: 600px; position: relative">
                <i class="uil-search-alt"></i>
                <input type="text" placeholder="Cari Mahasiswa" autocomplete="off" class="form-control rounded-md" style="width: 100%;">
            </div>
        </div>

        <div class="mt-5">
            <loading :loading="loading" spinner-class="justify-center">
                <b-table striped :items="items" :fields="fields" class="stripped-table">
                    <template #cell(fullname)="data">
                        <div class="flex items-center">
                            <div class="mr-2">
                                <a href="#">
                                    <img :src="data.item.user.images !== '' && data.item.user.images !== null ? data.item.user.images : require('@/assets/images/avatars/avatar-1.jpg')" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                                </a>
                            </div>
                            <div>
                                <h5 class="font-bold color-dark">{{ data.item.user.fullname }}</h5>
                                <span>{{ data.item.user.no_induk }}</span>
                            </div>
                        </div>
                    </template>
                    <template #cell(date)="data">
                        <div>
                            {{ formatDate(data.item.created_at) }}
                        </div>
                    </template>
                    <template #cell(action)="data">
                        <div>
                            <router-link v-if="data.item.status === 1" :to="{ name: 'AdminDetailPengajuanSkripsi', params: { id: data.item.id } }" class="btn btn-primary">Lihat Detail</router-link>
                            <a v-if="data.item.status === 2" href="" @click.prevent="openModalSchedule(data.item.id)" class="btn btn-primary">Jadwal</a>
                        </div>
                    </template>
                </b-table>
            </loading>
        </div>

        <modal name="modal-schedule" :height="330" :width="600" class="overflow-visible">
            <div class="p-5">
                <div class="d-flex justify-content-end align-items-center">
                    <a href="" @click.prevent="closeModalSchedule">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="currentColor"/>
                        </svg>
                    </a>
                </div>
                <div>
                    <div>
                        <h5 class="text-black font-semibold dark:text-gray-100 text-lg">
                            Note
                        </h5>
                        <p>Masukkan informasi jadwal sidang mahasiswa</p>

                        <div>
                            <datepicker
                                @selected="onDateSelected"
                                :bootstrap-styling="true"
                                placeholder="Pilih tanggal"
                                class="custom-input"
                                :disabled-dates="disabledDates"
                            />
                        </div>
                        <div>
                            <input
                                type="time"
                                @change="onTimeSelected"
                                placeholder="Pilih waktu"
                            >
                        </div>
                    </div>
                    <div class="flex justify-center items-center">
                        <button class="btn btn-primary search-button mt-3" @click.prevent="schedule">
                            <span v-if="modal.loading"><b-spinner small label="Loading..." /></span>
                            Kirim Jadwal
                        </button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import dayjs from 'dayjs'
    import Datepicker from 'vuejs-datepicker'

    export default {
        name: 'MahasiswaBimbingan',
        components: {
            Datepicker
        },
        methods: {
            ...mapActions('skripsi', { fetchSidang: 'fetchSidang', updateSidang: 'updateSidang' }),
            openModalSchedule (id) {
                this.$modal.show('modal-schedule')
                this.modal.data.id = id
            },
            closeModalSchedule () {
                this.$modal.hide('modal-schedule')
                this.modal.loading = false
                this.modal.data.id = null
                this.modal.data.date = null
                this.modal.data.time = null
            },
            onDateSelected (val) {
                this.modal.data.date = this.formatDateStore(val)
            },
            onTimeSelected (val) {
                this.modal.data.time = val.target.value
            },
            schedule () {
                this.modal.loading = true
                this.updateSidang({
                    id: this.modal.data.id,
                    data: {
                        date: this.modal.data.date,
                        time: this.modal.data.time
                    }
                }).then(() => {
                    this.closeModalSchedule()
                    this.fetchSidang()
                }).finally(() => {
                    this.modal.loading = false
                })
            }
        },
        computed: {
            disabledDates () {
                return {
                    to: dayjs().subtract(1, 'day').toDate()
                }
            }
        },
        data () {
            return {
                items: [],
                fields: [
                    {
                        key: 'fullname',
                        label: 'Nama Mahasiswa',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'date',
                        label: 'Tanggal Pengajuan',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'action',
                        label: 'Action',
                        tdClass: 'align-middle'
                    }
                ],
                loading: false,
                modal: {
                    loading: false,
                    data: {
                        id: null,
                        date: null,
                        time: null
                    }
                }
            }
        },
        mounted () {
            this.loading = true
            this.fetchSidang()
                .then(res => {
                    this.items = res
                })
                .catch(e => console.log(e))
                .finally(() => {
                    this.loading = false
                })
        }
    }
</script>
